{
  "env": "DEV",
  "apiKey": "AIzaSyBWaZ8Vvq2WQVvBMw9nHLk0FDpizUbgif0",
  "authDomain": "apfusion-spark.firebaseapp.com",
  "projectId": "apfusion-spark",
  "storageBucket": "apfusion-spark.appspot.com",
  "messagingSenderId": "290526465484",
  "appId": "1:290526465484:web:f0c320ff0316c32412e169",
  "vapidKey": "BIwK-AXlm3eeaHLTEDr3Ts5CPdrqMpv9byKSQxJ3JGuyniARxT_ZgF-qhcXHZ0dYDZWH6xABCGowgDRaR-bcSu8",
  "measurementId": "G-7LNFSW2SRS"
}
