import { SharedEnvironment } from '@chassis/shared/environment';
import * as FireBaseConfig from '../firebase/dev/firebase.config.json';
import config from '../../ngsw-config.json';

const {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  vapidKey,
} = FireBaseConfig;

export const environment: SharedEnvironment = {
  production: true,
  LOG_LEVEL: 'debug',
  SERVER_URL: 'https://staging.ecs.apfusion.com',
  BASEURL: 'https://staging.ecs.apfusion.com/api/v1',
  BASEURL_V4: 'https://staging.ecs.apfusion.com/api/v4',
  SYNCBASEURL: 'https://staging.ecs.apfusion.com/api/v1',
  PARTBASEURL: 'https://staging.ecs.apfusion.com/api/v1',
  WEBSOCKET_URL: 'wss://staging.ecs.apfusion.com/cable',
  ENVIRONMENT: 'staging',
  FEATURE_FLAG_KEY: '642592755ab5eb1349b8a404',
  SPREE_COOKIE_TOKEN: 'spreeCookieToken',
  CLOUD_WATCH: 'a8cbf1f8-c276-4abe-a647-8da1c42735e1',
  CART: 'cartToken',
  LOG_ROCKET: 'phalt8/spark-staging',
  CHAT_CONFIG: {
    appId: '239304632c98c391',
    authKey: '5c2dcd16a5cbf04ab6176d67d8cfb4a722570738',
    region: 'us',
  },
  GOOGLE_ID: 'G-31TKBNXWWX',
  FIREBASE_CONFIG: {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    vapidKey,
  },
  VERSION: config.appData.version,
  DEPLOYED: true,
  DESKTOPS3: 'https://spark-desktop-staging.s3.us-west-2.amazonaws.com',
};
