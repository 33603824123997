import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APFWindow } from '@chassis/shared/models';

if (environment.production) {
  enableProdMode();

  if (environment.INTERCOM_ID) {
    (window as unknown as APFWindow).loadIntercom(environment.INTERCOM_ID);
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
