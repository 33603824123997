import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, filter, forkJoin, map, of, switchMap } from 'rxjs';
import { SharedActions } from '@chassis/shared/actions';
import { AboutSelectors } from '@chassis/about';
import * as RegistrationActions from './software-registration.actions';
import { SoftwareRegistrationService } from '../services/software-registration.service';
import { LoggerService } from '@chassis/shared/services/logger';
import {
  SoftwareRegistration,
  SoftwareRegistrationType,
} from '../store/software-registration.model';
import { ElectronService } from '@chassis/shared/services/electron';
import {
  selectDesktopRegistration,
  selectPwaRegistration,
} from './software-registration.selectors';
import { selectUser } from '@chassis/auth/shared';

@Injectable()
export class SoftwareRegistrationEffects {
  readonly actions$ = inject(Actions);
  readonly electronService = inject(ElectronService);
  readonly service = inject(SoftwareRegistrationService);
  readonly store = inject(Store);
  readonly logger = inject(LoggerService);

  globalInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.initPostAuth),
      map(() =>
        this.electronService.isElectron
          ? RegistrationActions.initDesktop()
          : RegistrationActions.initWeb(),
      ),
    ),
  );

  initDesktop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActions.initDesktop),
      concatLatestFrom(() => [
        this.store.select(selectUser),
        this.store.select(AboutSelectors.selectDesktopVersion),
        this.store.select(AboutSelectors.selectWebVersion),
      ]),
      switchMap(([, user, desktopVersion, webVersion]) =>
        forkJoin([
          this.service.init(
            SoftwareRegistrationType.DESKTOP,
            user,
            desktopVersion,
          ),
          this.service.init(SoftwareRegistrationType.WEB, user, webVersion),
        ]),
      ),
      map(([desktopRegistration, pwaRegistration]) =>
        RegistrationActions.initDesktopSuccess({
          desktopRegistration,
          pwaRegistration,
        }),
      ),
      catchError((error) =>
        of(RegistrationActions.initDesktopFailed({ error })),
      ),
    ),
  );

  initWeb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActions.initWeb),
      concatLatestFrom(() => [
        this.store.select(selectUser),
        this.store.select(AboutSelectors.selectWebVersion),
      ]),
      switchMap(([, user, webVersion]) =>
        this.service.init(SoftwareRegistrationType.WEB, user, webVersion),
      ),
      map((pwaRegistration) =>
        RegistrationActions.initWebSuccess({ pwaRegistration }),
      ),
      catchError((error) => of(RegistrationActions.initWebFailed({ error }))),
    ),
  );

  registerNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActions.registerPushNotifications),
      concatLatestFrom(() =>
        this.electronService.isElectron
          ? this.store.select(selectDesktopRegistration)
          : this.store.select(selectPwaRegistration),
      ),
      filter(
        ([{ deviceToken }, currentRegistration]) =>
          !!currentRegistration && !!deviceToken,
      ),
      map(([{ deviceToken }, currentRegistration]) => {
        const softwareRegistration = {
          ...(currentRegistration as SoftwareRegistration),
          device_token: deviceToken,
        };
        return RegistrationActions.update({ softwareRegistration });
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActions.update),
      concatLatestFrom(({ softwareRegistration }: any) =>
        softwareRegistration.type === SoftwareRegistrationType.DESKTOP
          ? this.store.select(AboutSelectors.selectDesktopVersion)
          : this.store.select(AboutSelectors.selectWebVersion),
      ),
      switchMap(([{ softwareRegistration }, version]) => {
        const payload = version
          ? { ...softwareRegistration, version }
          : softwareRegistration;
        return this.service.update(softwareRegistration.type, payload).pipe(
          map((softwareRegistration) =>
            RegistrationActions.updateSuccess({ softwareRegistration }),
          ),
          catchError((error) =>
            of(RegistrationActions.updateFailed({ error })),
          ),
        );
      }),
    ),
  );
}
