import { BuyerRoutes } from '@chassis/buyer/routing';

export interface Notificationable {
  type: NotificationType;
  title: string;
  body: string;
  meta: unknown;
  silent: boolean;
  chimeType: string | null;
  badge: string;
  get icon(): string;
  get primaryPath(): string | undefined;
}

export enum BadgeType {
  Default = 'https://shop.apfusion.com/assets/apf.svg',
}

export enum NotificationType {
  Messenger = 'messenger',
  General = 'general',
  Order = 'order',
}

interface NotificationDataProps {
  type: NotificationType;
  title: string;
  body: string;
  silent: boolean;
  chimeType: string | null;
  badge: BadgeType;
}

export interface NotificationPayload<T, U> {
  messageId: string;
  notification: T;
  data: U;
}

// Abstract
export type UnknownNotificationPayload = NotificationPayload<unknown, any>;

// Comet Chat
export interface MessengerNotificationMetadata {
  conversationId: string;
  text: string;
}

export interface MessengerNotificationPart {}

export interface MessengerDataPart {
  alert: string;
  sound: string;
  title: string;
  message: string;
}

export interface MessengerNotificationPayloadData
  extends NotificationPayload<MessengerNotificationPart, MessengerDataPart> {}

// APF Nofications
export interface BaseNotificationPart {
  title: string;
  body: string;
  silent?: boolean;
  badge?: string;
}

export interface BaseDataPart {
  type: string;
  source: string;
}

// General APF Notification
export interface BaseNotificationPayloadData
  extends NotificationPayload<BaseNotificationPart, BaseDataPart> {}

// Order APF Notification
export interface OrderDataPart extends BaseDataPart {
  order: {
    id: number;
    number: string;
    pw: {
      wo_id: string;
      wo_number: string;
      wo_revision: number;
      dt_id: string;
      dt_number: string;
      dt_revision: string;
      po_id: string;
      po_number: string;
    };
  };
}

export interface OrderNotificationPayloadData
  extends NotificationPayload<BaseNotificationPart, OrderDataPart> {}

export interface OrderNotificationMetadata {
  orderId: number;
  orderNumber: string;
  pw: {
    wo_id: string;
    wo_number: string;
    wo_revision: number;
    dt_id: string;
    dt_number: string;
    dt_revision: string;
    po_id: string;
    po_number: string;
  };
}

export class BaseNotification<T> implements Notificationable {
  static parse(
    payload: NotificationPayload<unknown, any>,
  ): Notificationable | null {
    const { data } = payload;
    const { type, message } = data;
    const scopedType =
      !type && !!message
        ? NotificationType.Messenger
        : (type as NotificationType);

    switch (scopedType) {
      case NotificationType.Messenger:
        return new MessengerNotification(
          payload as MessengerNotificationPayloadData,
        );
      case NotificationType.Order:
        return new OrderNotification(payload as OrderNotificationPayloadData);
      case NotificationType.General:
        return new GeneralNotification(payload as BaseNotificationPayloadData);
      default:
        return null;
    }
    return null;
  }

  type: NotificationType;
  title: string;
  body: string;
  meta!: T;
  silent: boolean;
  chimeType: string | null;
  badge: string;

  get icon(): string {
    return this.badge;
  } // Electron uses icon...

  get primaryPath(): string | undefined {
    return;
  }

  constructor(props: NotificationDataProps) {
    this.type = props.type;
    this.title = props.title;
    this.body = props.body;
    this.silent = props.silent;
    this.chimeType = props.chimeType;
    this.badge = props.badge;
  }
}

// Comet Chat Nofication

export class MessengerNotification
  extends BaseNotification<MessengerNotificationMetadata>
  implements Notificationable
{
  override get primaryPath(): string | undefined {
    return `/${BuyerRoutes.messenger}`;
  }

  constructor(payload: MessengerNotificationPayloadData) {
    const { title: _sender } = payload.data;
    const message = payload.data?.message
      ? JSON.parse(payload.data.message)
      : null;

    const type = NotificationType.Messenger;
    const title = 'apfusion messenger';
    const body = `You have a new unread message from ${_sender}`;
    const silent = true;
    const badge = BadgeType.Default;
    const chimeType = null;

    super({ type, title, body, silent, badge, chimeType });
    this.meta = {
      text: message?.text,
      conversationId: message?.conversationId,
    };
  }
}

export class GeneralNotification
  extends BaseNotification<unknown>
  implements Notificationable
{
  constructor(payload: BaseNotificationPayloadData) {
    const { notification } = payload;

    const { title, body, silent: _silent, badge: _badge } = notification;

    const type = NotificationType.General;
    const silent = _silent === undefined ? true : _silent;
    const badge =
      _badge === undefined
        ? BadgeType.Default
        : (_badge as unknown as BadgeType);
    const chimeType = null;

    super({ type, title, body, silent, badge, chimeType });
  }
}

// Order APF Notification

export class OrderNotification
  extends BaseNotification<OrderNotificationMetadata>
  implements Notificationable
{
  override get primaryPath(): string | undefined {
    return `/${BuyerRoutes.orders}`;
  }

  constructor(payload: OrderNotificationPayloadData) {
    const { notification, data } = payload;

    const { title, body, silent: _silent, badge: _badge } = notification;
    const { order } = data;

    const type = NotificationType.Order;
    const silent = _silent === undefined ? true : _silent;
    const badge =
      _badge === undefined
        ? BadgeType.Default
        : (_badge as unknown as BadgeType);
    const chimeType = null;

    super({ type, title, body, silent, badge, chimeType });
    this.meta = {
      orderId: order.id,
      orderNumber: order.number,
      pw: order.pw,
    };
  }
}
