import { Routes } from '@angular/router';
import {
  AuthLoginGuard,
  RedirectGuard,
  UnAuthGuard,
} from '@chassis/shared/navigation';
import { LDFlags, Yms } from '@chassis/shared/models';
import { canMatchElectron } from '@chassis/shared/guards/desktop-guard';
import { StoreSelectionViewComponent } from '@chassis/desktop/connect/frontend';
import { BuyerRoutes } from '@chassis/buyer/routing';
import { SharedRoutes } from '@chassis/shared/routes';
import { SellerRoutes } from '@chassis/seller/routing';

const routeToPath = (route: string): string => {
  return route.replace(/^\//, '');
};

export const appRoutes: Routes = [
  {
    path: routeToPath(SharedRoutes.auth),
    canActivateChild: [UnAuthGuard],
    loadChildren: () =>
      import('@chassis/auth/login').then((m) => m.AuthLoginModule),
  },
  {
    path: '',
    canActivate: [AuthLoginGuard],
    canActivateChild: [AuthLoginGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        children: [],
        canActivate: [RedirectGuard],
      },
      {
        path: routeToPath(SharedRoutes.storeSelection),
        data: {
          yms: [Yms.Powerlink],
          root: SharedRoutes.storeSelection,
          flag: LDFlags.order,
        },
        canActivate: [canMatchElectron],
        component: StoreSelectionViewComponent,
      },
      {
        path: routeToPath(SharedRoutes.permissionDenied),
        loadComponent: () =>
          import('@chassis/shared/components/no-permission').then(
            (m) => m.NoPermissionComponent,
          ),
      },
      {
        path: routeToPath(SharedRoutes.notFound),
        loadChildren: () => import('@chassis/error').then((m) => m.ErrorModule),
      },

      {
        path: routeToPath(BuyerRoutes.root),
        canActivate: [],
        loadChildren: () =>
          import('@chassis/buyer/root').then((m) => m.BuyerModule),
      },
      {
        path: routeToPath(SellerRoutes.root),
        canActivate: [],
        loadChildren: () =>
          import('@chassis/seller/root').then((m) => m.SellerModule),
      },
      {
        path: routeToPath(SharedRoutes.styles),
        loadChildren: () =>
          import('@chassis/shared/styles').then((m) => m.StylesModule),
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];
