import { createAction, props } from '@ngrx/store';
import { SoftwareRegistration } from './software-registration.model';

export enum Type {
  InitDesktop = '[SoftwareRegistration] Init Desktop',
  InitDesktopSuccess = '[SoftwareRegistration] Init Desktop Success',
  InitDesktopFailed = '[SoftwareRegistration] Init Desktop Failed',

  InitWeb = '[SoftwareRegistration] Init Web',
  InitWebSuccess = '[SoftwareRegistration] Init Web Success',
  InitWebFailed = '[SoftwareRegistration] Init Web Failed',

  RegisterPushNotifications = '[SoftwareRegistration] RegisterNotifications',

  Update = '[SoftwareRegistration] Update',
  UpdateSuccess = '[SoftwareRegistration] Update Success',
  UpdateFailed = '[SoftwareRegistration] Update Failed',
}

export const initDesktop = createAction(Type.InitDesktop);

export const initDesktopSuccess = createAction(
  Type.InitDesktopSuccess,
  props<{
    pwaRegistration: SoftwareRegistration;
    desktopRegistration: SoftwareRegistration;
  }>(),
);

export const initDesktopFailed = createAction(
  Type.InitDesktopFailed,
  props<{ error: string }>(),
);

export const initWeb = createAction(Type.InitWeb);

export const initWebSuccess = createAction(
  Type.InitWebSuccess,
  props<{ pwaRegistration: SoftwareRegistration }>(),
);

export const initWebFailed = createAction(
  Type.InitWebFailed,
  props<{ error: string }>(),
);

export const registerPushNotifications = createAction(
  Type.RegisterPushNotifications,
  props<{ deviceToken: string }>(),
);

export const update = createAction(
  Type.Update,
  props<{ softwareRegistration: SoftwareRegistration }>(),
);

export const updateSuccess = createAction(
  Type.UpdateSuccess,
  props<{ softwareRegistration: SoftwareRegistration }>(),
);

export const updateFailed = createAction(
  Type.UpdateFailed,
  props<{ error: string }>(),
);
