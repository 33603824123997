import { Injectable } from '@angular/core';
import { debounceTime, groupBy, map, mergeAll, Subject } from 'rxjs';
import { ChimeType } from './chime.model';

@Injectable({
  providedIn: 'root',
})
export class ChimeService {
  static readonly DEBOUNCE_TIME = 500;

  audio!: HTMLAudioElement;
  debouceQueue$ = new Subject<ChimeType>();

  constructor() {
    this.debouceQueue$
      .pipe(
        groupBy((type) => type),
        map((group) => group.pipe(debounceTime(ChimeService.DEBOUNCE_TIME))),
        mergeAll(),
      )
      .subscribe(async (type: ChimeType) => await this.playSound(type));
  }

  async play(type: ChimeType) {
    this.debouceQueue$.next(type);
  }

  private async playSound(type: ChimeType) {
    if (!type) {
      return;
    }
    const player = this.getPlayer();
    player.src = type;
    return await player.play();
  }

  private getPlayer() {
    this.audio = this.audio || new Audio();
    return this.audio;
  }
}
