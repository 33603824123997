export enum SoftwareRegistrationType {
  DESKTOP = 'SparkDesktopSoftwareInstall',
  WEB = 'SparkPwaSoftwareInstall',
}

export interface SoftwareRegistration {
  id: number;
  type: SoftwareRegistrationType;
  version?: string | null;
  install_id?: string;
  system_information?: { [key: string]: any };
  device_token?: string;
}

export type SoftwarRegistrationPayload = Partial<SoftwareRegistration>;
