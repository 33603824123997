import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  debounceTime,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import * as PushActions from './push-notifications.actions';
import { PushNotificationsService } from '../services/push-notifications.service';
import { LoggerService } from '@chassis/shared/services/logger';
import { ChimeService, ChimeType } from '@chassis/shared/services/chime';
import {
  ChatActions,
  ChatConversation,
  ChatSelectors,
} from '@chassis/chat/shared';
import { ElectronService } from '@chassis/shared/services/electron';
import { RegistrationActions } from '@chassis/software-registration';
import { selectCurrentRoute } from '@chassis/shared/actions';
import { Route } from '@angular/router';
import {
  BaseNotification,
  MessengerNotification,
  Notificationable,
  NotificationType,
} from '@chassis/shared/push-notifications';
import { IpcChannels } from '@chassis/shared/models';
import { AllRoutes, RouteData } from '@chassis/shared/routes';

@Injectable()
export class PushNotificationsEffects {
  readonly actions$ = inject(Actions);
  readonly service = inject(PushNotificationsService);
  readonly chimeService = inject(ChimeService);
  readonly electronService = inject(ElectronService);
  readonly store = inject(Store);
  readonly logger = inject(LoggerService);

  readonly notificationDebounce = 500;

  globalInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RegistrationActions.initWebSuccess,
        RegistrationActions.initDesktopSuccess,
      ),
      map(() => PushActions.init()),
    ),
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushActions.init),
      switchMap(() => this.service.requestToken()),
      switchMap((deviceToken) => {
        if (!deviceToken) {
          return throwError(() => 'No PushNotification Token Available');
        }
        const actions: Action[] = [PushActions.initSuccess()];

        if (deviceToken) {
          actions.push(
            RegistrationActions.registerPushNotifications({ deviceToken }),
          );

          // actions.push(
          //   fromPromise(CometChat.registerTokenForPushNotification(token))
          // )
        }

        return actions;
      }),
      catchError((error) => {
        this.logger.error('[PushNotificationsService]', error);
        return of(PushActions.initFailed({ error }));
      }),
    ),
  );

  getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushActions.initSuccess),
      switchMap(() => this.service.getNotifications()),
      debounceTime(this.notificationDebounce),
      map((payload) => {
        const notification = BaseNotification.parse(payload);

        if (!notification) {
          return PushActions.receivedInvalidNotification({ notification });
        }

        if (notification?.type === NotificationType.Messenger) {
          return PushActions.receivedMessengerNotification({
            notification: notification as MessengerNotification,
          });
        }

        return PushActions.receivedApfNotification({ notification });
      }),
    ),
  );

  receivedApfNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushActions.receivedApfNotification),
      switchMap((action) => {
        const { notification } = action;
        const hasFocus = this.hasFocus();
        return [
          PushActions.showNotification({
            notification,
            enabled: !hasFocus,
          }),
          PushActions.showBadge({
            enabled: !hasFocus,
          }),
          PushActions.playChime({
            chimeType: notification.chimeType || ChimeType.Notification,
            enabled: !hasFocus,
          }),
        ];
      }),
    ),
  );

  receivedMessengerNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushActions.receivedMessengerNotification),
      concatLatestFrom(() => [
        this.store.select(ChatSelectors.selectTotalUnreadMessageCount),
        this.store.select(selectCurrentRoute),
        this.store.select(ChatSelectors.selectSelectedConversation),
      ]),
      switchMap(([action, unreadCount, activeRoute, activeConversation]) => {
        const { notification } = action;

        const isActiveRoute = this.isActiveRouteMessenger(activeRoute);
        const isActiveConversation = this.fromCurrentConversation(
          notification,
          activeConversation,
        );

        return [
          PushActions.showNotification({
            notification,
            enabled: !isActiveRoute && !isActiveConversation,
          }),
          PushActions.showBadge({
            unreadCount: unreadCount || 0,
            enabled: !isActiveRoute && !isActiveConversation,
          }),
          PushActions.playChime({
            chimeType: notification.chimeType || ChimeType.Notification,
            enabled: !isActiveRoute && !isActiveConversation,
          }),
        ];
      }),
    ),
  );

  showNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PushActions.showNotification),
        tap(async ({ notification, enabled }) => {
          if (enabled) {
            await this.showNotification(notification);
          }
        }),
      ),
    { dispatch: false },
  );

  showBadge$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PushActions.showBadge),
        tap(({ unreadCount, enabled }) => {
          if (enabled) {
            this.showBadge(unreadCount);
          }
        }),
      ),
    { dispatch: false },
  );

  playChime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PushActions.playChime),
        tap(async ({ chimeType, enabled }) => {
          if (enabled) {
            await this.playChime(chimeType as ChimeType);
          }
        }),
      ),
    { dispatch: false },
  );

  destroyToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.logoutSuccess),
        switchMap(() => this.service.deleteToken()),
      ),
    { dispatch: false },
  );

  async showNotification(notification: Notificationable) {
    if (this.electronService.isElectron) {
      return this.electronService.apfElectron?.send(
        IpcChannels.notificationShow,
        notification,
      );
    }

    const { title } = notification;
    return Promise.resolve(new Notification(title, notification));
  }

  showBadge(unreadCount?: number) {
    if (this.electronService.isElectron) {
      return this.electronService.apfElectron?.send(
        IpcChannels.taskbarBadgeShow,
        unreadCount,
      );
    }
  }

  async playChime(chimeType: ChimeType) {
    await this.chimeService.play(chimeType);
  }

  private hasFocus(): boolean {
    return !!document?.hasFocus();
  }

  private isActiveRouteMessenger(route: Route): boolean {
    if (!route?.data) {
      return false;
    }
    return AllRoutes.messenger === (route.data as RouteData).root;
  }

  private fromCurrentConversation(
    notification: MessengerNotification,
    conversation: ChatConversation | null,
  ): boolean {
    return (
      notification.meta?.conversationId === conversation?.getConversationId()
    );
  }
}
