import { createAction, props } from '@ngrx/store';
import {
  BaseNotification,
  MessengerNotification,
  Notificationable,
} from '@chassis/shared/push-notifications';

export enum Type {
  Init = '[Push] Init',
  InitSuccess = '[Push] Init Success',
  InitFailed = '[Push] Init Failed',
  ReceivedMessengerNotification = '[Push] Received Messenger Notification',
  ReceivedApfNotification = '[Push] Received APF Notification',
  ReceivedInvalidNotification = '[Push] Received Invalid Notification',
  ShowNotification = '[Push] Show Notification',
  ShowBadge = '[Push] Show Favicon Badge',
  PlayChime = '[Push] Play Chime',
}

export const init = createAction(Type.Init);

export const initSuccess = createAction(Type.InitSuccess);

export const initFailed = createAction(
  Type.InitFailed,
  props<{ error: string }>(),
);

export const receivedInvalidNotification = createAction(
  Type.ReceivedInvalidNotification,
  props<{ notification: any }>(),
);

export const receivedApfNotification = createAction(
  Type.ReceivedApfNotification,
  props<{ notification: Notificationable }>(),
);

export const receivedMessengerNotification = createAction(
  Type.ReceivedMessengerNotification,
  props<{ notification: MessengerNotification }>(),
);

export const showNotification = createAction(
  Type.ShowNotification,
  props<{ enabled: boolean; notification: BaseNotification<unknown> }>(),
);

export const showBadge = createAction(
  Type.ShowBadge,
  props<{ enabled: boolean; unreadCount?: number }>(),
);

export const playChime = createAction(
  Type.PlayChime,
  props<{ enabled: boolean; chimeType: string }>(),
);
