import { Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { IpcChannels, ShortcutModel } from '@chassis/shared/models';
import { IpcService } from '@chassis/shared/services/ipc';
import { selectYardConfig } from '@chassis/yard-management';

@Injectable({ providedIn: 'root' })
export class ShortcutsUiService {
  public shortcuts: ShortcutModel[] = [];
  public errors = {};
  constructor(
    @Optional() private ipcService: IpcService,
    private store: Store,
  ) {
    this.store
      .select(selectYardConfig)
      .pipe(
        filter(() => !!this.ipcService),
        filter((yardConfig) => !!yardConfig && yardConfig.id),
        take(1),
      )
      .subscribe(async (yardConfig) => {
        const results = await this.ipcService?.invoke(
          IpcChannels.initShortcuts,
          JSON.stringify(yardConfig),
        );
        if (results) {
          this.shortcuts = results.shortcuts;
          this.errors = results.errors;
        }
      });
  }

  async fetch() {
    const results = await this.ipcService?.invoke(IpcChannels.getShortcuts);

    this.shortcuts = results.shortcuts;
    this.errors = results.errors;
    return { shortcuts: this.shortcuts, errors: this.errors };
  }
}
