import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SOFTWARE_REGISTRATION_FEATURE_KEY,
  SoftwareRegistrationState,
} from './software-registration.reducer';

export const selectSoftwareRegistrationState =
  createFeatureSelector<SoftwareRegistrationState>(
    SOFTWARE_REGISTRATION_FEATURE_KEY,
  );

export const selectDesktopRegistration = createSelector(
  selectSoftwareRegistrationState,
  (state) => {
    return state.desktopRegistration;
  },
);

export const selectPwaRegistration = createSelector(
  selectSoftwareRegistrationState,
  (state) => {
    return state.pwaRegistration;
  },
);
