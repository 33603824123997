import { Action, createReducer, on } from '@ngrx/store';
import {
  SoftwareRegistration,
  SoftwareRegistrationType,
} from './software-registration.model';
import * as Actions from './software-registration.actions';

export interface SoftwareRegistrationState {
  loading: boolean;
  loaded: boolean;
  error?: Error | string;
  desktopRegistration?: SoftwareRegistration;
  pwaRegistration?: SoftwareRegistration;
}

export const SOFTWARE_REGISTRATION_FEATURE_KEY = 'softwareRgistration';
export interface SoftwareRegistrationPartialState {
  readonly [SOFTWARE_REGISTRATION_FEATURE_KEY]: SoftwareRegistrationState;
}

export const initialSoftwareRegistrationState: SoftwareRegistrationState = {
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialSoftwareRegistrationState,
  on(Actions.initDesktop, Actions.initWeb, () => {
    return {
      loading: true,
      loaded: false,
      error: undefined,
      desktopRegistration: undefined,
      pwaRegistration: undefined,
    };
  }),
  on(
    Actions.initDesktopSuccess,
    (state, { desktopRegistration, pwaRegistration }) => {
      return {
        loading: false,
        loaded: true,
        error: undefined,
        desktopRegistration,
        pwaRegistration,
      };
    },
  ),
  on(Actions.initWebSuccess, (state, { pwaRegistration }) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: undefined,
      pwaRegistration,
    };
  }),
  on(Actions.initDesktopFailed, Actions.initWebFailed, (state, { error }) => {
    return {
      loading: false,
      loaded: true,
      error,
      desktopRegistration: undefined,
      pwaRegistration: undefined,
    };
  }),
  on(Actions.update, (state) => {
    return { ...state, loading: true, loaded: false, error: undefined };
  }),
  on(Actions.updateSuccess, (state, { softwareRegistration }) => {
    if (softwareRegistration.type === SoftwareRegistrationType.DESKTOP) {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: undefined,
        desktopRegistration: softwareRegistration,
      };
    }
    return {
      ...state,
      loading: false,
      loaded: true,
      error: undefined,
      pwaRegistration: softwareRegistration,
    };
  }),
  on(Actions.updateFailed, (state, { error }) => {
    return { ...state, loading: false, loaded: true, error };
  }),
);

export function softwareRegistrationReducer(
  state: SoftwareRegistrationState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
