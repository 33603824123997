import { inject, Injectable } from '@angular/core';
import {
  defer,
  distinctUntilChanged,
  iif,
  merge,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { LoggerService } from '@chassis/shared/services/logger';
import { ElectronService } from '@chassis/shared/services/electron';
import { IpcChannels } from '@chassis/shared/models';
import { UnknownNotificationPayload } from '@chassis/shared/push-notifications';
import { ENVIRONMENT, SharedEnvironment } from '@chassis/shared/environment';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  readonly env: SharedEnvironment = inject(ENVIRONMENT);
  readonly electronService = inject(ElectronService);
  readonly logger = inject(LoggerService);
  readonly firebaseMessaging = inject(AngularFireMessaging);

  token$!: Observable<string | null>;

  requestToken(): Observable<string | null> {
    this.token$ = iif(
      () => this.electronService.isElectron,
      defer(() => this.requestIpcToken()),
      defer(() => this.requestFirebaseToken()),
    ).pipe(
      distinctUntilChanged(),
      tap((token) => {
        this.logger.info('[PushNotificationsService.requestToken]', token);
      }),
    );
    return this.token$;
  }

  deleteToken(): Observable<boolean> {
    return this.token$.pipe(
      switchMap((token) =>
        iif(
          () => this.electronService.isElectron,
          defer(() => this.deleteIpcToken(token)),
          defer(() => this.deleteFirebaseToken(token)),
        ),
      ),
    );
  }

  getNotifications(): Observable<UnknownNotificationPayload> {
    return iif(
      () => this.electronService.isElectron,
      defer(() => this.requestIpcNotifications()),
      defer(() => this.requestFirebaseNotifications()),
    ).pipe(
      distinctUntilChanged(),
      tap((notification) =>
        this.logger.debug(
          '[PushNotificationsService.getNotifications]',
          notification,
        ),
      ),
    );
  }

  private requestFirebaseToken(): Observable<string | null> {
    return this.firebaseMessaging.requestToken;
  }

  private requestIpcToken(): Observable<string> {
    this.electronService.send(
      IpcChannels.notificationServiceStart,
      this.env.FIREBASE_CONFIG,
    );

    return merge(
      this.electronService.getEvents$(IpcChannels.notificationServiceStarted),
      this.electronService.getEvents$(
        IpcChannels.notificationServiceTokenUpdated,
      ),
    );
  }

  private deleteFirebaseToken(token: string | null): Observable<boolean> {
    if (!token) {
      return of(false);
    }
    return this.firebaseMessaging.deleteToken(token);
  }

  private deleteIpcToken(token: string | null): Observable<boolean> {
    this.electronService.send(IpcChannels.notificationServiceReset, token);
    return of(true);
  }

  private requestFirebaseNotifications(): Observable<any> {
    // Note - This will only provide push notifications when the app is focused.
    // All background notifications are handled purely by spark/src/firebase-messaging-sw.js
    return this.firebaseMessaging.messages;
  }

  private requestIpcNotifications(): Observable<any> {
    return this.electronService.getEvents$(
      IpcChannels.notificationServiceReceived,
    );
  }
}
