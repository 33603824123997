import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SoftwareRegistrationEffects } from './store/software-registration.effects';
import { StoreModule } from '@ngrx/store';
import {
  SOFTWARE_REGISTRATION_FEATURE_KEY,
  softwareRegistrationReducer,
} from './store/software-registration.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      SOFTWARE_REGISTRATION_FEATURE_KEY,
      softwareRegistrationReducer,
    ),
    EffectsModule.forFeature([SoftwareRegistrationEffects]),
  ],
})
export class SoftwareRegistrationModule {}
