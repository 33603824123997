import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT, SharedEnvironment } from '@chassis/shared/environment';
import { map, Observable, tap } from 'rxjs';
import { StorageService } from '@chassis/shared/services/storage';
import { HttpClient } from '@angular/common/http';
import {
  SoftwareRegistration,
  SoftwareRegistrationType,
} from '../store/software-registration.model';
import { User } from '@chassis/shared/models';

@Injectable({
  providedIn: 'root',
})
export class SoftwareRegistrationService {
  readonly env: SharedEnvironment = inject(ENVIRONMENT);
  readonly storageService = inject(StorageService);
  readonly httpClient = inject(HttpClient);
  readonly typePath = {
    [SoftwareRegistrationType.DESKTOP]: 'spark_desktop',
    [SoftwareRegistrationType.WEB]: 'spark_pwa',
  };

  init(
    type: SoftwareRegistrationType,
    user: User,
    version: string | null,
  ): Observable<SoftwareRegistration> {
    const storedId = this.storageService.getItem(
      this.getStorageKey(user, type),
    );
    if (storedId) {
      return this.load(type, storedId);
    }
    const softwareRegistration = version ? { version } : { version: 'unknown' };
    return this.create(type, user, softwareRegistration);
  }

  create(
    type: SoftwareRegistrationType,
    user: User,
    data: Partial<SoftwareRegistration>,
  ): Observable<SoftwareRegistration> {
    const url = this.getBaseUrl(type);
    const payload = { software_install: data };
    return this.httpClient
      .post<{ data: SoftwareRegistration }>(url, payload)
      .pipe(
        map(({ data }) => data),
        tap((registration) => {
          if (registration?.install_id) {
            this.storageService.setItem(
              this.getStorageKey(user, type),
              registration.install_id,
            );
          }
        }),
      );
  }

  load(
    type: SoftwareRegistrationType,
    installId: string,
  ): Observable<SoftwareRegistration> {
    const url = this.getBaseUrl(type, installId);
    return this.httpClient
      .get<{ data: SoftwareRegistration }>(url)
      .pipe(map(({ data }) => data));
  }

  update(
    type: SoftwareRegistrationType,
    data: Partial<SoftwareRegistration>,
  ): Observable<SoftwareRegistration> {
    const url = this.getBaseUrl(type, data.install_id);
    const payload = { software_install: data };
    return this.httpClient
      .patch<{ data: SoftwareRegistration }>(url, payload)
      .pipe(map(({ data }) => data));
  }

  private getBaseUrl(
    type: SoftwareRegistrationType,
    installId?: string,
  ): string {
    const baseUrl = `${this.env.BASEURL_V4}/software_installs/${this.typePath[type]}`;
    return installId ? `${baseUrl}/${installId}` : baseUrl;
  }

  private getStorageKey(user: User, type: SoftwareRegistrationType): string {
    return `${user.id}:${type}`;
  }
}
